import React, { Fragment, useEffect, useState } from "react";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import { useHistory } from "react-router";
import { Button, Col, Container, Input, Row } from "reactstrap";
import PaymentBackTitle from "../components/PaymentBackTitle";
import { PageNotAvailableError } from "../../../modules/error_wrappers/PageNotAvailableError";
import { useMixPanelGQL } from "../../../api_client/UseGQL";
import { PulseLoader } from "react-spinners";
import { trackEvent } from "../../../modules/analytics";
import { useCreditCardHandler } from "./hooks/creditCardHandler";
import useConvergeMethod from "./ConvergeMethod";
import useStripeMethod from "./StripeMethod";

function CreditCardMethod() {
  const [state, setState] = useSimpleContext();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const history = useHistory();
  const mixPanelGqlHooks = useMixPanelGQL();
  const { alert } = useCreditCardHandler(setErrorMsg);
  const [name, setName] = useState("");
  const [nameErrorMsg, setNameErrorMsg] = useState("");
  const [customerCreditCard, setCustomerCreditCard] = useState("");
  const { getCustomerCreditCard: getCustomerCreditCardConverge } =
    useConvergeMethod(setErrorMsg);
  const {
    getCustomerCreditCard: getCustomerCreditCardStripe,
    CARD_ELEMENT_OPTIONS,
    CardElement,
  } = useStripeMethod(setErrorMsg);
  const debitFrequencyObj = state.transaction || state.recurringPlan;
  const convergeMerchantAccount =
    debitFrequencyObj?.toBankAccount?.convergeMerchantAccount;
  const isUsingConverge = debitFrequencyObj?.company?.isUsingConverge;

  useEffect(() => {
    if (state.transaction) {
      trackEvent(
        "debitCreditMethod",
        {
          transactionId: state.transaction.identifier,
          companyId: state.transaction.company?.identifier,
          contactId: state.transaction.contact.identifier,
        },
        mixPanelGqlHooks
      );
    } else if (state.recurringPlan) {
      trackEvent(
        "debitCreditMethod",
        {
          transactionId: state.recurringPlan.identifier,
          companyId: state.recurringPlan.company.identifier,
          contactId: state.recurringPlan.contact.identifier,
        },
        mixPanelGqlHooks
      );
    }
  }, []);

  useEffect(() => {
    const isRecurring = state?.recurringPlan;
    // todo: implement this later for converge
    // if (!ajaxStatus || !convergePayStatus) {
    //   // Stripe.js has not yet loaded.
    //   // Make  sure to disable form submission until Stripe.js has loaded.
    //   return;
    // }
    if (customerCreditCard) {
      if (isRecurring) {
        setState({
          ...state,
          recurringPlan: {
            ...state.recurringPlan,
            customerCreditCard: {
              ...state.recurringPlan?.customerCreditCard,
              ...customerCreditCard,
            },
          },
        });
      } else {
        setState({
          ...state,
          transaction: {
            ...state.transaction,
            customerCreditCard: {
              ...state.transaction?.customerCreditCard,
              ...customerCreditCard,
            },
          },
        });
      }

      history.push({
        pathname: "review",
        search: "?paymentMethod=creditCard",
      });
    } else {
      setLoading(false);
    }
  }, [customerCreditCard]);

  const creditCardsEnabled =
    state.transaction?.company.creditCardsEnabled ||
    state.recurringPlan?.company.creditCardsEnabled;

  if (!creditCardsEnabled) {
    return (
      <PageNotAvailableError errorBodyText="Sorry, credit cards are not enabled for this transaction." />
    );
  }

  const handleSubmit = async (event) => {
    setLoading(true);
    setErrorMsg(undefined);
    setNameErrorMsg(undefined);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!name || name.length < 2) {
      setNameErrorMsg("You must enter the cardholder name.");
      setLoading(false);
      return;
    }

    setLoading(true);
    if (isUsingConverge) {
      getCustomerCreditCardConverge(
        setLoading,
        setCustomerCreditCard,
        name,
        convergeMerchantAccount
      );
    } else {
      getCustomerCreditCardStripe(setLoading, setCustomerCreditCard, name);
    }
    return false;
  };

  const isLoading = false || loading;
  return (
    <Fragment>
      {alert}
      <PaymentBackTitle
        title="Pay via Credit Card"
        backAction={() => history.push("method")}
      />
      <Container>
        <Row>
          <Col md="8" lg="8" className="m-auto pb-3">
            <form onSubmit={handleSubmit}>
              <div className="pb-4">
                <div
                  className={[
                    "StripeElement",
                    "MgStripeElement",
                    nameErrorMsg ? "invalid" : "",
                  ].join(" ")}
                >
                  <Input
                    type="text"
                    placeholder="Name as shown on card"
                    name="cardholderName"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    invalid={!!nameErrorMsg}
                    style={{ boxShadow: "none", padding: "8px" }}
                  />
                </div>
                {nameErrorMsg && (
                  <span className="text-danger">{nameErrorMsg}</span>
                )}
                {!isUsingConverge && (
                  <CardElement options={CARD_ELEMENT_OPTIONS} />
                )}
                {errorMsg && <span className="text-danger">{errorMsg}</span>}
              </div>

              <Button
                disabled={isLoading}
                block
                className="btn-primary btn-lg mt-0 mb-3"
              >
                {isLoading ? (
                  <PulseLoader color="white" size={10} />
                ) : (
                  "Review & pay"
                )}
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default CreditCardMethod;

CreditCardMethod.propTypes = {};
