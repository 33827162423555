import React, { Fragment, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { useLocation } from "react-router";
import { publicTransaction } from "../../api_client/queries/transactions";
import { trackEvent } from "../../modules/analytics";
import useGQL from "../../api_client/UseGQL";
import { useSimpleContext } from "../../contexts/SimpleContext";
import PayDebit from "./PayDebit";
import AcceptCredit from "./AcceptCredit";
import PaymentNavbar from "../../components/navbars/PaymentNavbar";
import useExitPrompt from "../../hooks/useExitPrompt";
import * as Sentry from "@sentry/browser";
import PopOutOverlay from "../../components/overlays/PopOutOverlay";
import CheckoutSupportForm from "../../components/forms/CheckoutSupportForm";

function Payment() {
  const [, setShowExitPrompt] = useExitPrompt(true);
  const location = useLocation();
  const { params } = useRouteMatch();
  const gqlHooks = useGQL();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useSimpleContext();
  const history = useHistory();
  const [overlayDetails, setOverlayDetails] = useState(false);

  useEffect(() => {}, [state]);

  useEffect(() => {
    try {
      Sentry.configureScope((scope) => scope.setTransactionName("Checkout"));
    } catch (e) {
      console.log("sentry not loaded");
    }
  }, []);

  useEffect(() => {
    setShowExitPrompt(true);
    return () => {
      setShowExitPrompt(false);
    };
  }, []);

  useEffect(() => {
    const paymentIdentifier = params.identifier;
    let input = { transactionId: paymentIdentifier };
    let output = {
      identifier: true,
      issuedBy: {
        email: true,
      },
      amount: true,
      description: true,
      direction: true,
      states: {
        state: true,
        createdAt: true,
      },
      padType: true,
      statement: true,
      createdAt: true,
      estDepositDate: true,
      contact: {
        identifier: true,
        email: true,
        name: true,
      },
      company: {
        name: true,
        fullName: true,
        displayImage: true,
        identifier: true,
        settings: {
          securityQuestion: true,
          useSecurityQuestion: true,
        },
        creditCardsEnabled: true,
        isUsingConverge: true,
      },
      toBankAccount: {
        institution: true,
        title: true,
        accountNumber: true,
        institutionNumber: true,
        convergeMerchantAccount: {
          merchantId: true,
          pin: true,
          apiUser: {
            userId: true,
          },
        },
      },
      fromBankAccount: {
        institution: true,
        title: true,
        accountNumber: true,
        institutionNumber: true,
        contact: {
          email: true,
        },
      },
      customerCreditCard: {
        brand: true,
        last4: true,
      },
    };
    publicTransaction(input, output, gqlHooks).then((response) => {
      if (response) {
        if (response.data) {
          trackEvent(
            "loadedPaymentPage",
            {
              requestId: paymentIdentifier,
              companyId: response.data.company.identifier,
            },
            gqlHooks
          );
          setState({ transaction: response.data });
          setLoading(false);
        }
      } else {
        history.push("/lost");
      }
    });
  }, []);

  useEffect(() => {
    if (state?.transaction) {
      if (
        state.transaction.direction === "DEBIT" &&
        !location.pathname.includes("debit")
      ) {
        history.push(`/payment/debit/${state.transaction.identifier}`);
      } else if (
        state.transaction.direction === "CREDIT" &&
        !location.pathname.includes("credit")
      ) {
        history.push(`/payment/credit/${state.transaction.identifier}`);
      }
      if (state.transaction.states[0].state !== 7) {
        setShowExitPrompt(false);
        const paid = "paid=true";
        let search = location.search;
        if (!search.includes(paid)) {
          search += search ? "&" + paid : "?" + paid;
        }
        history.push({ search: search });
      }
    }
  }, [state]);

  //todo return checkout skeleton while loading

  if (loading) {
    return null;
  }

  return (
    <Fragment>
      <div className="payment-wrapper section-to-print">
        <PaymentNavbar
          companyLogo={state?.transaction.company.displayImage}
          companyName={state?.transaction.company.fullName}
          summaryText={`One-time payment ${
            state?.transaction.direction === "DEBIT" ? "to" : "from"
          } ${
            state?.transaction.company.fullName ||
            state.transaction.company.name
          }`}
          amount={state?.transaction.amount}
          toggleSupport={() => setOverlayDetails(!overlayDetails)}
        />
        <PopOutOverlay
          isOpen={overlayDetails}
          toggle={() => {
            setOverlayDetails(!overlayDetails);
          }}
        >
          <CheckoutSupportForm
            toggleSupport={() => setOverlayDetails(!overlayDetails)}
          />
        </PopOutOverlay>
        <div className="payment-content">
          <Switch>
            <Route
              path={[
                "/payment/debit/:identifier",
                ["/payment/debit/:identifier/"],
              ]}
              render={() => <PayDebit />}
            />
            <Route
              path={[
                "/payment/credit/:identifier",
                "/payment/credit/:identifier/",
              ]}
              render={() => <AcceptCredit />}
            />
            <Redirect from={"/payment/"} to={"/lost"} />
          </Switch>
        </div>
      </div>
    </Fragment>
  );
}

export default Payment;
