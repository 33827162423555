import React, { Fragment, useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import {
  appName,
  formattedDollarValue,
  GREENFOOT_IDs,
} from "../../../modules/Helpers";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import { useHistory } from "react-router";
import CreditSecurityQuestion from "../components/CreditSecurityQuestion";
import PaymentDetailsHeader from "../components/PaymentDetailsHeader";
import { trackEvent } from "../../../modules/analytics";
import useGQL from "../../../api_client/UseGQL";

function PaymentDetailsStep() {
  const [state] = useSimpleContext();
  const history = useHistory();
  const gqlHooks = useGQL();

  useEffect(() => {
    if (state.transaction.direction === "CREDIT") {
      trackEvent(
        "creditDetails",
        {
          transactionId: state.transaction.identifier,
          companyId: state.transaction.company?.identifier,
          contactId: state.transaction.contact?.identifier,
        },
        gqlHooks
      );
    } else {
      trackEvent(
        "debitDetails",
        {
          transactionId: state.transaction.identifier,
          companyId: state.transaction.company?.identifier,
          contactId: state.transaction.contact?.identifier,
        },
        gqlHooks
      );
    }
  }, []);

  const creditCardsEnabled = state.transaction.company?.creditCardsEnabled;
  // TODO: REACTIVATE Re-enable button
  return (
    <Fragment>
      <PaymentDetailsHeader
        transaction={state.transaction}
        customClass="desktop"
      />
      <Container>
        <div className="d-flex flex-column mt-4 mobile">
          <h3 className="text-primary text-center mb-4">
            {state.transaction.direction === "CREDIT"
              ? "Payment from"
              : "Payment request from"}
          </h3>
          <div className="d-flex flex-row align-items-center justify-content-center">
            <img
              width={"50px"}
              src={state.transaction.company?.displayImage}
              className={`${
                !state.transaction.company?.displayImage && "d-none"
              }`}
              alt="Company Logo"
            />
            <h4 className="ml-3 mb-0 font-weight-600">
              {state.transaction.company.fullName ||
                state.transaction.company.name}
            </h4>
          </div>
        </div>
        <Row className="m-0">
          <Col sm="12" md="10" className="m-auto p-0">
            <h4 className="text-center font-weight-400 pt-4 pb-4 mb-0">
              {state.transaction.company.fullName ||
                state.transaction.company.name}{" "}
              {state.transaction.direction === "CREDIT" ? (
                <>
                  sent you a <b>one-time payment</b> through {appName} for the
                  amount of:
                </>
              ) : (
                <>
                  has requested a <b>one-time payment</b> through {appName} for
                  the amount of:
                </>
              )}
            </h4>
          </Col>
        </Row>
        <Row className="m-0">
          <Col>
            <h1 className="text-center font-weight-400 pb-4 mb-0">
              {formattedDollarValue(state.transaction.amount)}
            </h1>
          </Col>
        </Row>
      </Container>
      <Container style={{ background: "#F1F0F3" }} className="pt-3 pb-2">
        <div className="payment-info">
          <Row>
            <Col>
              <h5>Paid to</h5>
              <p>
                {state.transaction.direction === "CREDIT" ? (
                  <>{state.transaction.contact.email}</>
                ) : (
                  <>
                    {state.transaction.company.fullName ||
                      state.transaction.company.name}
                  </>
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Statement</h5>
              <p>{state.transaction.statement}</p>
            </Col>
          </Row>
          {state.transaction.description && (
            <Row>
              <Col>
                <h5>Notes</h5>
                <p className="font-italic">
                  <q>{state.transaction.description}</q>
                </p>
              </Col>
            </Row>
          )}
        </div>
      </Container>
      <Container>
        {state.transaction.direction === "CREDIT" && <CreditSecurityQuestion />}
        {state.transaction.direction === "DEBIT" &&
          (!GREENFOOT_IDs.includes(state?.transaction.company.identifier) ? (
            <div className="d-flex justify-content-center">
              <Button
                className="btn-lg btn-primary mt-4 mb-4"
                onClick={() =>
                  creditCardsEnabled
                    ? history.push("method")
                    : history.push("bank-transfer")
                }
              >
                <div className="d-flex align-items-center flex-grow-1">
                  <span className="mr-3">Pay request now</span>
                  <div>
                    <ArrowRightIcon height={20} width={20} />
                  </div>
                </div>
              </Button>
            </div>
          ) : (
            <>
              <div className="greenfoot-message">
                Payment Service Unavailable
              </div>
              <div className="greenfoot-message">
                Please call Greenfoot at (800) 380-9384 to arrange payment
              </div>
            </>
          ))}
      </Container>
    </Fragment>
  );
}

export default PaymentDetailsStep;

PaymentDetailsStep.propTypes = {};
