import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import GetSavedBadge from "../saved/GetSavedBadge";
import LoginOnlineMethod from "./LoginOnlineMethod";
import ManualEntryMethod from "./ManualEntryMethod";
import UpdateKyc from "./UpdateKyc";
import BankConnectedStep from "../steps/BankConnectedStep";
import PaymentBackTitle from "../components/PaymentBackTitle";
import { useSimpleContext } from "../../../contexts/SimpleContext";
import BankBack from "../../../assets/img/checkout/bank-back-updated.svg";

import Cloud1 from "../../../assets/img/checkout/bank-cloud-1.svg";
import Cloud2 from "../../../assets/img/checkout/bank-cloud-2.svg";
import BankFrontGroup from "../../../assets/img/checkout/bank-front-group.svg";
import Person from "../../../assets/img/checkout/person.svg";

import { useHistory } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import { trackEvent } from "../../../modules/analytics";
import useGQL from "../../../api_client/UseGQL";

function BankTransferMethod(props) {
  const [state] = useSimpleContext();
  const history = useHistory();
  const [bankMethod, setBankMethod] = useState("");
  const [alertState, setAlertState] = useState(null);
  const gqlHooks = useGQL();

  BankTransferMethod.defaultProps = {
    collectBankInfoOnly: false,
  };

  useEffect(() => {
    if (
      props.collectBankInfoOnly !== true &&
      state.bankAccount &&
      state.bankAccount?.missingKyc?.length === 0
    ) {
      setBankMethod("connected");
    }
  }, []);

  useEffect(() => {
    if (props.collectBankInfoOnly === true) {
      trackEvent(
        "supplierInviteBankConnection",
        {
          contactId: props.contactIdentifier,
        },
        gqlHooks
      );
    } else {
      if (state.transaction) {
        if (state.transaction.direction === "CREDIT") {
          trackEvent(
            "creditBankTransfer",
            {
              transactionId: state.transaction.identifier,
              companyId: state.transaction.company?.identifier,
              contactId: state.transaction.contact.identifier,
            },
            gqlHooks
          );
        } else {
          trackEvent(
            "debitBankTransfer",
            {
              transactionId: state.transaction.identifier,
              companyId: state.transaction.company?.identifier,
              contactId: state.transaction.contact.identifier,
            },
            gqlHooks
          );
        }
      } else {
        trackEvent(
          "recurringBankTransfer",
          {
            recurringId: state.recurringPlan.identifier,
            companyId: state.recurringPlan.company.identifier,
            contactId: state.recurringPlan.contact.identifier,
          },
          gqlHooks
        );
      }
    }
  }, []);

  const showManualEntryAlert = () => {
    setAlertState(
      <SweetAlert
        warning
        title="Error logging in to your bank"
        onConfirm={() => hideAlert()}
        showConfirm={true}
        showCancel={false}
        confirmBtnText="Okay"
      >
        Please try entering bank account details manually in the form provided.
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlertState(null);
  };

  const checkoutFlow = state?.transaction || state?.recurringPlan;
  const goBackToMethodPage =
    (state?.transaction?.direction === "DEBIT" || state?.recurringPlan) &&
    checkoutFlow?.company.creditCardsEnabled;

  if (bankMethod === "login") {
    return (
      <LoginOnlineMethod
        collectBankInfoOnly={props.collectBankInfoOnly}
        contactIdentifier={props.contactIdentifier}
        setBankMethod={setBankMethod}
        showManualEntryAlert={showManualEntryAlert}
      />
    );
  } else if (bankMethod === "manual") {
    return (
      <Fragment>
        {alertState}
        <ManualEntryMethod
          collectBankInfoOnly={props.collectBankInfoOnly}
          contactIdentifier={props.contactIdentifier}
          setBankMethod={setBankMethod}
        />
      </Fragment>
    );
  } else if (bankMethod === "update") {
    return <UpdateKyc setBankMethod={setBankMethod} />;
  } else if (bankMethod === "connected") {
    return (
      <BankConnectedStep
        collectBankInfoOnly={props.collectBankInfoOnly}
        contactIdentifier={props.contactIdentifier}
        setBankMethod={setBankMethod}
      />
    );
  } else if (bankMethod === "") {
    return (
      <Fragment>
        {props.collectBankInfoOnly !== true ? (
          <PaymentBackTitle
            title={
              goBackToMethodPage
                ? "Pay via Bank Transfer"
                : "Accept via Bank Transfer"
            }
            backAction={
              goBackToMethodPage
                ? () => history.push("method")
                : () => history.push("details")
            }
          />
        ) : null}
        <Container>
          <Row className="bg-default position-relative bg-radial-gradient">
            <div className="col-12">
              <h3
                className="text-white text-center"
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                Connect your Bank
              </h3>
            </div>
            <div className="cloud-images-body">
              <div className="cloud-images">
                <img className="cloud-1" src={Cloud1} alt="Cloud" />
                <img className="cloud-2" src={Cloud2} alt="Cloud" />
              </div>
            </div>

            <div className="row">
              <div className="col-11 col-sm-10 row mx-auto px-0">
                <div className="col-5 d-flex align-items-end">
                  <div className="connect-bank-image1">
                    <img src={BankFrontGroup} alt="Bank Graphic" />
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <div className="connect-bank-image1 mb-3 mb-sm-5">
                    <img className="person" src={Person} alt="Bank Graphic" />
                  </div>
                </div>
                <div className="col-5 px-sm-4 d-flex align-items-end">
                  <div className="connect-bank-image1">
                    <img src={BankBack} alt="Bank Graphic" />
                  </div>
                </div>
              </div>
            </div>

            <div className="connect-bank-image2">
              {/* <img src={BankBack} alt="Bank Graphic" /> */}
            </div>
            <Col
              sm="8"
              lg="8"
              className="col-8 mx-auto pb-3 checkout-btn-group"
            >
              <Button
                className="btn-primary method-btn btn-md btn-sm-lg mt-0 mb-2"
                block
                onClick={() => setBankMethod("login")}
              >
                Login with my bank
              </Button>
              <Button
                className="btn-primary method-btn btn-md btn-sm-lg mt-0 mb-3 ml-0"
                block
                onClick={() => setBankMethod("manual")}
              >
                Enter bank account details manually*
              </Button>
              <div className="text-white text-center">
                *Please select manual entry if paying with a line of credit
              </div>
            </Col>
          </Row>
        </Container>
        {props.collectBankInfoOnly !== true ? <GetSavedBadge /> : null}
      </Fragment>
    );
  }
}

export default BankTransferMethod;

BankTransferMethod.propTypes = {};
